<template>
  <div/>
</template>

<script>
import { useStore} from "vuex"
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted } from "vue"
import axios from "axios";
import qs from "qs";
import { kakaoCallbackUrl, kakaoClientId } from "@/utils/OAuthLogin";

export default {
  setup: function(){
		const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const sessionId = computed(() => store.state.sessionId);
    const code = route.query.code;
		const userData = computed(() => store.state.userData);

    const login = async (email, id, nickname, phonenumber) => {
      const ip = await fetch('https://api64.ipify.org').then(res=>res.text());      
      if(localStorage.getItem("isSnsLink") === "true"){
        router.replace({
            path: "/mypage/sns",
            query: { email, snsJoinFlag: "02", snsCi: id }
        });
        return;
      }
			store.commit("SET_USERDATA", {userData: {
				...userData.value,
				sns_ci: id,
				snsJoinFlag: '02',
			}});
      store.dispatch("mmb01/fetchMmb01", {
          mem_id: email,
          sessionid: sessionId.value,
          ip_addr: ip,
          sns_join_flag: "02",
          sns_ci: id,
          hp_no: phonenumber,
					fcm_token:userData.value.fcmToken,
        }).then(x=>{
          if(x.Res_cd === "-1"){
            store.commit("SET_USERDATA", {userData:{}})
            store.commit("mmb01/SET_SNS_INFO", { email, snsJoinFlag: "02", snsCi: id, snsNickname: nickname, snsPhoneNumber: phonenumber });
            router.replace("/join/step1");
          }
          else if(x.Res_cd === "0000" && x.Res_data.length > 0){
            store.commit("SET_USERDATA", {userData: x.Res_data[0]})
            router.replace("/");
          }
          else if(x.Res_cd === "9999" || x.Res_cd === "9998"){
            store.commit("SET_USERDATA", {userData: {}});
          	store.commit("SET_USERDATA", {userData: x.Res_data[0]})
            alert(x.Res_msg)
            router.replace("/");
          }
          else if(x.Res_cd === "9991"){
            store.commit("SET_USERDATA", {userData: {
						    	...userData.value,
						    	hp_no:phonenumber
						    }
						})
            router.replace("/mypage/snslink");
          }
        });
    }
    onMounted(()=>{
      const data = qs.stringify({
        'grant_type': 'authorization_code',
        'client_id': kakaoClientId,
        'redirect_uri': kakaoCallbackUrl,
        'code': code 
      });
      const config = {
        method: 'post',
        url: 'https://kauth.kakao.com/oauth/token',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        const data = response.data;
        window.Kakao.Auth.setAccessToken(data.access_token);
        window.Kakao.API.request({
          url: '/v2/user/me',
          data: { 
            property_keys: ["kakao_account.email", "kakao_account.ci", "properties.nickname", "kakao_account.phone_number"]
          },
          success : (data) =>{
            //console.log(data);
            let phoneNumber = null
            if(data.kakao_account.has_phone_number){
              if(data.kakao_account.phone_number.startsWith("+82 ")){
                phoneNumber = data.kakao_account.phone_number.replace("+82 ", "0");
              }
              else{
                phoneNumber = data.kakao_account.phone_number;
              }
            }
            login(data.kakao_account.email, data.kakao_account.ci, data.properties.nickname, phoneNumber)
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    });

    return {
      login,
      code
    }
  }
}
</script>